import React from 'react';

const Students = () => {
    return (
        <div>
            Студенты
        </div>
    );
};

export default Students;