import React from 'react';

const Requests = () => {
    return (
        <div>
            Заявки
        </div>
    );
};

export default Requests;